<template>
  <v-app id="inspire">
    <div class="app-context">
      <v-app-bar
        absolute
        color="#fcb69f"
        dark
        shrink-on-scroll
        src="https://picsum.photos/1920/1080?random"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
          ></v-img>
        </template>

        <div class="logo" style="margin : auto; width : 1000px">
          <v-row>
            <v-col cols="12" md="2"
              ><img class="header_logo" src="@/assets/images/logo_default.svg"
            /></v-col>
            <v-col cols="12" md="8"
              ><h1
                style="font-size : 32px; position : relative; top : 3px; margin-left : 20px"
              >
                상품 상세정보
              </h1></v-col
            >
          </v-row>
        </div>

        <v-spacer></v-spacer>
      </v-app-bar>
      <div style="margin : 150px 20px 0 20px">
        <div style="position: relative; width: 80%; left: 15%">
          <div
            style="width: 100%; text-align: center; font-size: 32px; margin: 10px"
          >
            <span>상품 Detail 미리보기</span>
            <image-viewer
              v-if="showImage"
              :src="this.photo"
              @close="showImage = false"
              style="z-index: 1"
              :imgList="prod"
            />
          </div>
          <v-container>
            <div class="row">
              <div class="col-md-5 col-sm-5 col-xs-12">
                <div style="border : 2px soild #000">
                  <img
                    :src="
                      'https://www.drrefer.net/api/pic/' + this.prod.attchId
                    "
                    @click="
                      viewPhoto(
                        'https://www.drrefer.net/api/pic/' + prod.attchId
                      )
                    "
                    style="width : 100%; height : 100%"
                  />
                </div>
              </div>

              <div class="col-md-7 col-sm-7 col-xs-12">
                <div style="height : 30px" />
                <div class="pl-6">
                  <p class="display-1 mb-0">
                    {{ this.prod.prodNm }}
                  </p>
                  <v-card-actions class="pa-0">
                    <p class="headline font-weight-light pt-3">
                      {{ this.prod.price }}원
                    </p>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                  <p
                    class="subtitle-1 font-weight-thin"
                    v-html="this.prod.prodDesc"
                  ></p>
                </div>
                <div class="pl-6">
                  <p class="title">결제방식</p>
                  <v-radio-group row>
                    <v-radio label="신용카드" value="card"></v-radio>
                    <v-radio label="계좌이체" value="vbank"></v-radio>
                  </v-radio-group>
                  <div>
                    <v-chip
                      class="ma-2"
                      color="red"
                      text-color="white"
                      style="left : -10px"
                    >
                      신청서 작성필요
                    </v-chip>
                    <!--
                <v-chip
                  class="ma-2"
                  color="blue"
                  text-color="white"
                  style="left : -10px"
                >
                  신청서 작성완료
                </v-chip>
                !-->
                  </div>
                  <div style="height : 20px" />
                  <v-btn class="primary white--text" outlined tile dense large
                    ><v-icon style="margin-right: 15px;">mdi-cash-usd</v-icon>
                    <span
                      style="font-size : 20px; line-height : 10px; position : relative; left : -7px;"
                      >결제 하기</span
                    >
                  </v-btn>
                </div>
              </div>
              <div class="row" style="margin-top : 40px;">
                <div
                  style="width: 100%; text-align: center; font-size: 32px; margin: 10px"
                >
                  <v-btn
                    class="primary white--text"
                    outlined
                    tile
                    dense
                    large
                    style="margin: 0 50px 0 -70px;"
                    @click="prodModify()"
                    ><v-icon style="margin-right: 15px;">mdi-update</v-icon>
                    <span style="font-size : 20px; line-height : 10px;"
                      >수정 하기</span
                    >
                  </v-btn>
                  <v-btn
                    class="error white--text"
                    outlined
                    tile
                    dense
                    large
                    @click="prodDelete()"
                    id="focusControl"
                    ><v-icon style="margin-right: 15px;">mdi-delete</v-icon>
                    <span style="font-size : 20px; line-height : 10px;"
                      >삭제 하기</span
                    >
                  </v-btn>
                </div>
              </div>
            </div>
          </v-container>
          <div style="height: 100px" />
        </div>
      </div>
      <default-popup v-if="checkPopup" :popupSet="popupSet" />
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import ImageViewer from "@/components/ImageViewer";
import DefaultPopup from "@/components/modal/DefaultPopup";
export default {
  components: { ImageViewer, DefaultPopup },
  data() {
    return {
      photo: "",
      prod: {},
      popupSet: {},
      foducs: {},
      showImage: false,
      mapLocation: {
        posX: 37.578801,
        posY: 126.966441,
      },
      pr: {
        logoAttchId: null,
        hospNm: null,
        hospIntro: null,
        conConts: null,
        drPicAttchId: null,
        drNm: null,
        drHist: null,
        doctIntro: null,
        addrDtl: null,
        hospTel: null,
        hospFaxNo: null,
        etcConts: null,
        mapAttchId: null,
        picAttchId: null,
        prAttchId: null,
        rsvUrl: null,
        homepage: null,
        drId: null,
        docAddvTitle: null,
        docAddvConts: null,
        etcPage: [
          {
            type: null,
            value: null,
          },
          {
            type: null,
            value: null,
          },
          {
            type: null,
            value: null,
          },
        ],
      },
    };
  },
  created() {
    // this.getBasicData();
    // this.getPromotionData();
    this.getProd();
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  mounted() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapGetters("promotion", ["GET_PROMOTION"]),
    ...mapGetters("admin", ["GET_PROD_DATA"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_CHILD_LOADER"]),
    ...mapActions("join", ["FETCH_BASIC_TARGET_UN_AUTH"]),
    ...mapActions("promotion", ["FETCH_PROMOTION_TARGET_UN_AUTH"]),
    ...mapActions("admin", ["BRING_PROD_DATA"]),
    viewPhoto(src) {
      this.photo = src;
      //console.log(this.photo);
      this.showImage = true;
    },
    // async getBasicData() {
    //   try {
    //     await this.FETCH_BASIC_TARGET_UN_AUTH(this.$route.params.code).then(
    //       () => {
    //         //console.log("찾기 회원정보", this.GET_BASIC);
    //         if (this.GET_BASIC.hospNm !== null) {
    //           this.pr.hospNm = this.GET_BASIC.hospNm;
    //         }
    //         if (this.GET_BASIC.drNm !== null) {
    //           this.pr.drNm = this.GET_BASIC.drNm;
    //         }
    //         if (this.GET_BASIC.drId !== null) {
    //           this.pr.drId = this.GET_BASIC.drId;
    //         }
    //         if (this.GET_BASIC.addr !== null) {
    //           this.pr.addr = this.GET_BASIC.addr;
    //         }
    //         if (this.GET_BASIC.addrDtl !== null) {
    //           this.pr.addrDtl = this.GET_BASIC.addrDtl;
    //         }
    //         if (this.GET_BASIC.posX !== null) {
    //           this.mapLocation.posX = this.GET_BASIC.posX;
    //         }
    //         if (this.GET_BASIC.posY !== null) {
    //           this.mapLocation.posY = this.GET_BASIC.posY;
    //         }
    //       }
    //     );
    //   } catch (error) {
    //     console.log("error:", error);
    //   }
    // },
    // async getPromotionData() {
    //   /**
    //    *  삭제,변경 => 1
    //    *  유지,입력 => 0
    //    *
    //    */
    //   try {
    //     await this.FETCH_PROMOTION_TARGET_UN_AUTH(this.$route.params.code).then(
    //       () => {
    //         //console.log("찾기 조회정보", this.GET_PROMOTION);
    //         if (this.GET_PROMOTION.logoAttchId !== null) {
    //           this.pr.logoAttchId =
    //             "https://www.drrefer.net/api/pic/" +
    //             this.GET_PROMOTION.logoAttchId;
    //         }
    //         if (this.GET_PROMOTION.hospIntro !== null) {
    //           this.pr.hospIntro = this.GET_PROMOTION.hospIntro;
    //         }
    //         if (this.GET_PROMOTION.conConts !== null) {
    //           this.pr.conConts = this.GET_PROMOTION.conConts;
    //         }
    //         if (this.GET_PROMOTION.drPicAttchId !== null) {
    //           this.pr.drPicAttchId =
    //             "https://www.drrefer.net/api/pic/" +
    //             this.GET_PROMOTION.drPicAttchId;
    //         }
    //         if (this.GET_PROMOTION.drHist !== null) {
    //           this.pr.drHist = this.GET_PROMOTION.drHist;
    //         }
    //         if (this.GET_PROMOTION.doctIntro !== null) {
    //           this.pr.doctIntro = this.GET_PROMOTION.doctIntro;
    //         }
    //         if (this.GET_PROMOTION.hospTel !== null) {
    //           this.pr.hospTel = this.phoneFomatter(this.GET_PROMOTION.hospTel);
    //         }
    //         if (this.GET_PROMOTION.hospFaxNo !== null) {
    //           this.pr.hospFaxNo = this.phoneFomatter(
    //             this.GET_PROMOTION.hospFaxNo
    //           );
    //         }
    //         if (this.GET_PROMOTION.etcConts !== null) {
    //           this.pr.etcConts = this.GET_PROMOTION.etcConts;
    //         }
    //         if (this.GET_PROMOTION.mapAttchId !== null) {
    //           this.pr.mapAttchId =
    //             "https://www.drrefer.net/api/pic/" +
    //             this.GET_PROMOTION.mapAttchId;
    //         }
    //         if (this.GET_PROMOTION.picAttchId !== null) {
    //           this.pr.picAttchId =
    //             "https://www.drrefer.net/api/pic/" +
    //             this.GET_PROMOTION.picAttchId;
    //         }
    //         if (this.GET_PROMOTION.prAttchId !== null) {
    //           this.pr.prAttchId =
    //             "https://www.drrefer.net/api/pic/" +
    //             this.GET_PROMOTION.prAttchId;
    //         }
    //         if (this.GET_PROMOTION.rsvUrl !== null) {
    //           this.pr.rsvUrl = this.GET_PROMOTION.rsvUrl;
    //         }
    //         if (this.GET_PROMOTION.homepage !== null) {
    //           this.pr.homepage = this.GET_PROMOTION.homepage;
    //         }
    //         if (
    //           this.GET_PROMOTION.url1 !== null &&
    //           this.GET_PROMOTION.url1 !== ""
    //         ) {
    //           this.pr.etcPage[0].value = this.GET_PROMOTION.url1;
    //         }
    //         if (
    //           this.GET_PROMOTION.urlNm1 !== null &&
    //           this.GET_PROMOTION.urlNm1 !== "" &&
    //           this.GET_PROMOTION.urlNm1 !== "선택안함"
    //         ) {
    //           this.pr.etcPage[0].type = this.GET_PROMOTION.urlNm1;
    //         }
    //         if (
    //           this.GET_PROMOTION.url2 !== null &&
    //           this.GET_PROMOTION.url2 !== ""
    //         ) {
    //           this.pr.etcPage[1].value = this.GET_PROMOTION.url2;
    //         }
    //         if (
    //           this.GET_PROMOTION.urlNm2 !== null &&
    //           this.GET_PROMOTION.urlNm2 !== "" &&
    //           this.GET_PROMOTION.urlNm2 !== "선택안함"
    //         ) {
    //           this.pr.etcPage[1].type = this.GET_PROMOTION.urlNm2;
    //         }
    //         if (
    //           this.GET_PROMOTION.url3 !== null &&
    //           this.GET_PROMOTION.url3 !== ""
    //         ) {
    //           this.pr.etcPage[2].value = this.GET_PROMOTION.url3;
    //         }
    //         if (
    //           this.GET_PROMOTION.urlNm3 !== null &&
    //           this.GET_PROMOTION.urlNm3 !== "" &&
    //           this.GET_PROMOTION.urlNm3 !== "선택안함"
    //         ) {
    //           this.pr.etcPage[2].type = this.GET_PROMOTION.urlNm3;
    //         }
    //         if (
    //           this.GET_PROMOTION.docAddvTitle !== null &&
    //           this.GET_PROMOTION.docAddvTitle !== ""
    //         ) {
    //           this.pr.docAddvTitle = this.GET_PROMOTION.docAddvTitle;
    //         }
    //         if (
    //           this.GET_PROMOTION.docAddvConts !== null &&
    //           this.GET_PROMOTION.docAddvConts !== ""
    //         ) {
    //           this.pr.docAddvConts = this.GET_PROMOTION.docAddvConts;
    //         }
    //       }
    //     );
    //   } catch (error) {
    //     //console.log("error:", error);
    //   }
    // },
    async getProd() {
      //console.log("this.$route.params.code : ",this.$route.params.code);
     
     //유효성 검사
      if(this.$route.params.code === null 
        || this.$route.params.code === undefined)
      this.$route.params.code = "";

      var prodNoBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{8,14}$/;

      if(!prodNoBoo.test(this.$route.params.code)){
        this.SET_POPUP(true);
        this.popupSet.title = "존재하지 않는 상품번호";
        this.popupSet.content = "존재하지 않는 상품번호입니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      await this.BRING_PROD_DATA(this.$route.params.code).then(() => {
        this.prod = this.GET_PROD_DATA;
      });
      //console.log("prod",this.prod);
    },
    prodModify() {
      //console.log("modify");
      this.$router.push("/v/adminMain/prodModify/" + this.prod.prodNo).catch(()=>{});
    },
    prodDelete() {
      this.SET_POPUP(true);
      this.popupSet.title = "상품 삭제";
      this.popupSet.content =
        "상품을 삭제하시겠습니까? \n 확인 버튼을 누르면 삭제 및 리스트 페이지로 이동됩니다.";
      this.popupSet.popType = "custom_prod_delete";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.cancelBtnText = "취소";
      this.popupSet.destination = true;
      this.popupSet.prodParam = this.prod;
      this.popupSet.nextLink = "/v/adminMain/9";
    },
  },
  destroyed() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
};
</script>

<style>
.img-viewer {
  top: -200px !important;
}
.origin {
  position: relative !important;
  top: 340px !important;
  left: 90px !important;
}
</style>
